import { Component, Input, OnChanges } from '@angular/core';
import { AuthStatus } from '@interfaces/auth-status.model';
import { RouteUtilities } from '@utilities/route.utilities';

@Component({
  selector: 'app-login-prompt',
  templateUrl: './login-prompt.component.html',
  styleUrls: ['./login-prompt.component.scss'],
})
export class LoginPromptComponent implements OnChanges {
  @Input() public auth: AuthStatus;
  @Input() public headlineKey: string;
  @Input() public subHeadlineKey: string;
  @Input() public noAccountKey: string;
  @Input() public loginPromptKey: string;
  @Input() public guidedSearchPrompt: boolean;

  public authUrl: string;
  private routeUtilities = new RouteUtilities();

  ngOnChanges() {
    this.noAccountKey =
      this.noAccountKey || 'profile_reviews_login_prompt_no_account';
    this.loginPromptKey =
      this.loginPromptKey || 'profile_reviews_login_prompt_signup';
    this.setAuthUrl();
  }

  private setAuthUrl(): void {
    if (this.guidedSearchPrompt) {
      this.authUrl =
        this.auth.url +
        `/?RelayState=${this.routeUtilities.origin()}/?guided_search=guided_search_top_cost`;
    } else {
      this.authUrl = this.auth.url;
    }
  }
}
