<div *ngIf="!hideLinks">
  <div
    data-cy="network-selection-wizard-login-links"
    *ngIf="!suppressLoginFromConfig && !isBroker" class="text-base"
  >
    <span [innerHTML]="
     'network_selection_wizard_login_link' | translate: { url: auth?.url }">
    </span>
  </div>

  <div *ngIf="isBroker" class="text-base" data-cy="network-selection-wizard-broker-bypass">
    <span>Want to see all networks?</span>
    <zelis-button
      class="ml-1 text-palette-m3-system-blue-40"
      (click)="handleSeeAllNetworks()">
      Click here
   </zelis-button>
  </div>
</div>
